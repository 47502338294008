import { useState, useEffect } from 'react';

export default function useToken() {
  useEffect(() => {
    setLoading(true)
    const token = localStorage.getItem('token');
    const resetTokenInStorage = () => window.localStorage.removeItem('token');
    const verify = async () => {
        try {
            const response = await fetch('http://localhost:8080/verify', {
                body: JSON.stringify({
                    token
                }),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            if (response.status === 200) {
                setToken(token);
                setLoading(false)
                return;
            }
            setToken(null)
            resetTokenInStorage();
            setLoading(false)
        } catch (error) {
            setToken(null)
            resetTokenInStorage();
            setLoading(false)
            console.log(error)
        }
    }
    verify();
  }, [])

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const saveToken = userToken => {
    localStorage.setItem('token', userToken.token);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
    loading
  }
}